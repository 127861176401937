import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ForwardHeader = _resolveComponent("ForwardHeader")!
  const _component_MostDifficultQuestions = _resolveComponent("MostDifficultQuestions")!
  const _component_DefaultLayout = _resolveComponent("DefaultLayout")!

  return (_openBlock(), _createBlock(_component_DefaultLayout, null, {
    header: _withCtx(() => [
      _createVNode(_component_ForwardHeader)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_MostDifficultQuestions, {
        "licence-class": _ctx.licenceClass,
        "selected-question-id": _ctx.questionId
      }, null, 8, ["licence-class", "selected-question-id"])
    ]),
    _: 1
  }))
}